<template>
    <div class="warp" v-loading="Loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="tab tab-top">
            <div class="tab-item toptab active">排名</div>
            <div class="tab-item toptab">本期奖品</div>
            <div class="tab-item toptab">每期奖励</div>
            <div>
                <p style="padding:6px 6px;float:right;color:#060606;"><span>当前角色：{{ info.charac_name }} <span
                            style="color:#797979;" @click="LoginDisplay = 'display:block'">《换绑》</span></span></p>
            </div>
        </div>
        <div class="main">
            <div class="page page-1 active">
                <div class="pm_title">
                    <P style="position:absolute;left:286px;top:22px;font-size:20px;font-family:cursive;color:#f00;font-weight:700">{{info.config.phbtype}}</P>
                    <p class="wdpm">我的排名：{{ info.config.wdRanking }}名</p>
                    <p class="pmnr">{{ info.config.on_off }}</p>
                    <p class="pmnr">{{info.config.describe}}</p>
                </div>
                <div class="bg_table" style="overflow:auto;">
                    <table border="1">
                        <tr class="top_table">
                            <th>排名</th>
                            <th>角色</th>
                            <th>数量</th>
                            <th>预得奖励</th>
                        </tr>
                        <tr class="nr_table" v-for="(v, i)  in info.ranking_lis" :key="i">
                            <td><span>第{{ v.ranking }}名</span></td>
                            <td>{{ v.charac_name }}</td>
                            <td>{{ v.num }}</td>
                            <td>
                                <span v-if="v.reward.length <= 0">无奖</span>
                                <img v-else class="lj2" v-on:mouseover="lj_info($event, v.reward.code)"
                                    v-on:mouseout="removeActive($event)" :src="v.reward.ioc" :alt="v.reward.name"
                                    style="width:28px;height:28px;line-height:0px;position:relative;top:1px;">
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="page page-2">
                <P style="position:absolute;left:286px;top:22px;font-size:20px;font-family:cursive;color:#f00;font-weight:700">{{info.config.phbtype}}</P>
                <div class="jl_itmes">
                    <ul>
                        <li v-for="(v, i)  in info.plug_ranking_reward" :key="i">
                            <div class="jl-pic1">
                                <div class="jl_title">
                                    <h4>第{{ v.ranking }}名</h4>
                                </div>
                                <div class="pic-img">
                                    <img class="lj" v-on:mouseover="lj_info($event, v.code)"
                                        v-on:mouseout="removeActive($event)" :src="v.ioc" :alt="v.name">
                                </div>
                                <p class="p-t">{{ v.name }}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="page page-3">
                <P style="position:absolute;left:286px;top:22px;font-size:20px;font-family:cursive;color:#f00;font-weight:700">{{info.config.phbtype}}</P>
                <div class="pm_title" style="margin-top:92px">
                    <p class="pmnr">温馨提示：每期的奖励会自动发送到邮箱中，请注意查收！</p>
                </div>
                <div class="bg_table" style="overflow:auto;">
                    <table border="1">
                        <tr class="top_table">
                            <th>排次</th>
                            <th>当期结束日期</th>
                            <th>结算日期</th>
                            <th>获得奖励</th>
                        </tr>
                        <tr class="nr_table" v-for="(v, i)  in info.plug_consum_reward_log" :key="i">
                            <td><span>第{{ v.ranking }}名</span></td>
                            <td>{{ v.period }}</td>
                            <td>{{ v.create_time }}</td>
                            <td>
                                <span v-if="v.reward.length <= 0">无奖</span>
                                <span v-else>
                                    <img class="lj3" v-on:mouseover="lj_info($event, v.reward)"
                                        v-on:mouseout="removeActive($event)" :src="v.ioc"
                                        style="width:28px;height:28px;line-height:0px;position:relative;top:2px;">
                                </span>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        
        <div class="iteminfo">
            <div style="text-align:center;">物品礼包</div>
            <div v-for="(itmec, i) in itme_show_data" :key="i" style="padding:0px 6px;">{{ itmec.name }}x{{ itmec.num }}
            </div>
        </div>
        <div class="alert">
            <!-- 登陆框 -->
            <div class="alert_bg" :style="LoginDisplay">
                <div class="alert_main" style="margin-top:86px;width:350px;">
                    <div class="alert_title"><span>绑定|切换</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">游戏账号：</span><input type="text"
                                    v-model="login_info.username" class="service__input"
                                    style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">游戏密码：</span><input type="password"
                                    v-model="login_info.password" class="service__input"
                                    style="background:#000;border:1px solid #442e21;color:#abce21;width: 220px;"></p>
                            <p style="margin-bottom:6px;display:flex;"><span
                                    style="padding:0px 0px 0px 32px;">绑定角色：</span>
                                <select v-model="login_info.role"
                                    style="width:173px;background:#000;border:1px solid #442e21;color:#abce21;">
                                    <option v-for="(itmec, i) in role_data" :key="i" :value="itmec.charac_no">
                                        {{ itmec.charac_name }}
                                    </option>
                                </select>
                                <span
                                    style="background:#7478e4;padding:3px 10px;margin-left:2px;border-radius:4%;cursor:pointer;"
                                    @click="getRole">获取</span>
                            </p>
                            <div>
                                <span style="padding:0px 0px;color:#f90000;"><span
                                        style="display:inline-block;padding:0px 0px;">{{
                                                LoginError
                                        }}</span></span>
                            </div>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="btn" @click="login"
                                    style="margin:0px 0px;display:inline-block;cursor:pointer;">确定
                                </div>
                                <div class="btn" @click="LoginDisplay = 'display:none'"
                                    style="margin:0px 0px;display:inline-block;cursor:pointer;">取消</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <!-- 登陆框 end-->
            <!-- 弹出框 -->
            <div class="alert_bg" :style="MessageDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>
                            <div style="text-align:center;padding:10px 0px">
                                <div class="btn" @click="CloseAlert" style="margin:0px 0px;display:inline-block;">
                                    关闭</div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
            <div class="alert_bg" :style="SuccessDisplay">
                <div class="alert_main">
                    <div class="alert_title"><span>提示</span></div>
                    <div class="alert_con">
                        <div class="alert_com">
                            <span>{{ MessageMsg }}</span>

                            <div style="text-align:center;padding:10px 0px">
                                <div class="btn" @click="SuccessAlert" style="margin:0px 0px;display:inline-block;">关闭
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="alert_bottom"><span></span></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from "jquery";
import Vue from 'vue'
export default {
    name: "Wit",
    data () {
        return {
            MessageDisplay: "display:none", //弹出框显示
            SuccessDisplay: 'display:none',//成功弹出框
            LoginDisplay: "display:none", //登陆弹出框
            LoginError: '',
            MessageMsg: '', //弹出框消息
            Loading: false,
            info: {
                m_id: 0, //账号uid
                charac_no: 0, //角色ID
                charac_name: '', //角色名称
                ranking_lis: [], //排名数据
                config: {
                    wdRanking: 0,
                    on_off: "",
                    phbtype:'',
                    describe:'',
                },
                plug_ranking_reward: [],//奖品
                plug_consum_reward_log: [], //历史排名
            },
            login_info: {
                username: '',
                password: '',
                role: '',
            },
            itme_show_data: [], //展示物品用
            role_data: [],
        };
    },
    created () {
        this.getInfo()
    },
    methods: {
        //获取基本配置信息
        getInfo () {
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/ranking/GetRankingInfo",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.info = response.data.info
                    } else {
                        this.PopMessage(true, response.data.msg)
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //登陆
        login () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/PlugLogin",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                    role: this.login_info.role,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.LoginDisplay = "display:none";
                        this.getInfo();
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //获取角色
        getRole () {
            if (this.Loading == true) {
                return false;
            }
            this.Loading = true;
            this.axios({
                // 默认请求方式为get
                method: "post",
                url: Vue.prototype.config.webplugurl + "/getRole",
                // 传递参数
                data: {
                    key: this.$route.query.key,
                    keyid:this.$route.query.id,
                    username: this.login_info.username,
                    password: this.login_info.password,
                },
                responseType: "json",
            })
                .then((response) => {
                    this.Loading = false;
                    if (response.data.code == 200) {
                        this.role_data = response.data.info;
                    } else {
                        this.LoginError = response.data.msg;
                    }
                })
                .catch((error) => {
                    this.Loading = false;
                    // 请求失败，
                    console.log(error);
                });
        },
        //弹出框状态
        PopMessage (type, msg) {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
            this.MessageMsg = msg;
            if (type == false) {
                this.MessageDisplay = "display:block";
            } else {
                this.SuccessDisplay = "display:block";
            }
        },

        //关闭窗口
        CloseAlert () {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
        },
        SuccessAlert () {
            this.SuccessDisplay = "display:none";
            this.MessageDisplay = "display:none";
            this.getInfo();
        },
        lj_info ($event, itmes) {
            this.itme_show_data = itmes
            var container = document.getElementsByClassName("iteminfo");
            if (typeof (container) != "undefined") {
                container[0]
                var x = $event.x
                var y = $event.y
                console.log("11")
                if (x > 1167) {
                    container[0].style.left = (x - 77) + 'px'
                } else {
                    container[0].style.left = (x - 73) + 'px'
                }
                container[0].style.top = y + 'px'
                container[0].style.display = 'block'
                let item_h = container[0].offsetHeight + container[0].style.top;
                if (item_h > 435) {
                    y -= (item_h - 435);
                    container[0].style.top = y + 'px'
                }
            }
        },
        removeActive ($event) {
            console.log($event)
            var container = document.getElementsByClassName("iteminfo");
            if (typeof (container) != "undefined") {
                container[0].style.display = 'none'
            }
        },
    },
};
$(function () {
    $('.toptab').click(function () {
        var index = $(this).index();
        $('.page').removeClass('active');
        $('.toptab').removeClass('active');
        $(this).addClass('active');
        $('.page').eq(index).addClass('active');
    });
    $('.midtab').click(function () {
        var index = $(this).index();
        $('.quest-page-item').removeClass('active');
        $('.midtab').removeClass('active');
        $(this).addClass('active');
        $('.quest-page-item').eq(index).addClass('active');
    });
});
</script>

<style scoped>
@import "../../../public/static/plug/cera/static/css/game.css";
</style>

<style>
    #root,
    body,
    html {
        min-width: 0px;
    }
    </style>